export function toggleMenu() {
  const buttons = document.querySelectorAll('.navigation-left__item-button')

  buttons.forEach(function (button) {
    button.addEventListener('click', function () {
      const ul = this.nextElementSibling // находим следующий элемент после кнопки, который должен быть <ul>
      if (ul && ul.classList.contains('navigation-left-list')) {
        ul.classList.toggle('is-open')
      }
    })
  })
}
